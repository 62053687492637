/* eslint-disable no-underscore-dangle */
const isLocal = typeof window._env_ === 'undefined';

// check if runtime variables are defined, if not, use process var
const context = isLocal ? process.env : window._env_;

type Environment = 'development' | 'test' | 'production';

const ENVIRONMENT_URLS = {
  development: 'https://dev.gcp.optii.io/',
  test: 'https://test.gcp.optii.io/',
  production: 'https://optii.app/',
};

export const isEnvironment = (env: Environment[] | Environment) => {
  const UI_URL = window.location.origin;
  if (typeof env === 'string') {
    if (env === 'development') {
      return (
        process.env.NODE_ENV === 'development' ||
        UI_URL === ENVIRONMENT_URLS.development
      );
    }
    return UI_URL === ENVIRONMENT_URLS[env];
  }

  return Object.keys(ENVIRONMENT_URLS)
    .filter((environmentKey) => env.includes(environmentKey as Environment))
    .some((key) => {
      if ((key as Environment) === 'development') {
        return (
          process.env.NODE_ENV === 'development' ||
          UI_URL === ENVIRONMENT_URLS.development
        );
      }

      return UI_URL === ENVIRONMENT_URLS[key as keyof typeof ENVIRONMENT_URLS];
    });
};

// For now, checking the graphql server to determine env
function getGraphQlEnv() {
  const graphServer = context.REACT_APP_GRAPH_SERVER;
  if (graphServer.match(/\.dev\./)) {
    return 'DEV';
  }
  if (graphServer.match(/\.test\./)) {
    return 'TEST';
  }
  return 'PROD';
}

export function getEnvironment() {
  if (isLocal) return 'LOCAL';

  return getGraphQlEnv();
}

// A great way of debugging environment related configurations
// console.info("Configuration:", {
//   ...context,
//   ENV: getGraphQlEnv()
// });
export default {
  ...context,
  ENV: getGraphQlEnv(),
  isEnvironment,
};
